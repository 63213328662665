'use strict'
import settings from "@/settings";

const webSocketMixins = {
    data() {
        return {
            webSocket: null,
        }
    },
    methods: {
        // sendInstantSocket(type, id) {
        //     if (!type || !id) return false
        //     let wsUri = settings.socketHost
        //
        //     this.webSocket = new WebSocket(wsUri)
        //
        //     setTimeout(() => {
        //         if (this.webSocket.readyState === 1) {
        //             if (type === 'order') {
        //                 this.$store.state.webSocket.send(JSON.stringify({'action' : 'updateOrder', 'order_id' : id}))
        //             } else if (type === 'createOffer') {
        //                 this.$store.state.webSocket.send(JSON.stringify({'action' : 'updateOffer', 'offer_id' : id}))
        //             }
        //
        //             this.webSocket.close()
        //         }
        //     }, 150)
        // },
        createWebSocket() {
            //SOCKETS TEST
            let wsUri = this.settings.socketHost

            // this.webSocket = new WebSocket(wsUri)
            // this.$store.commit('createWebSocket', this.webSocket)
            this.$store.commit('createWebSocket', new WebSocket(wsUri))
            // if (!this.webSocket) return false

            // this.webSocket.addEventListener('open', () => {
            this.$store.state.webSocket.addEventListener('open', () => {
                this.socketConnectionError = false
                this.socketConnectionIsTerminated = false
                this.socketIsConnected = true
                const timeString = new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds()
                if (settings.isDevMode) {
                    console.log('[open] Connection established: ' + wsUri + ' - ' + timeString)
                } else {
                    console.log('[open] Connection established' + ' - ' + timeString)
                }
            })

            // this.webSocket.addEventListener('message', (event) => {
            this.$store.state.webSocket.addEventListener('message', (event) => {
                this.webSocketOnMessage(event)
            })

            // this.webSocket.addEventListener('close', (event) => {
            //   this.webSocket = null
            //   this.$store.commit('createWebSocket', null)
            //
            //
            //   this.socketIsConnected = false
            //   const timeString = new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds()
            //   setTimeout(() => {
            //     if (!this.socketIsConnected) {
            //       this.socketConnectionIsTerminated = true
            //     }
            //   }, 500)
            //   if (event.wasClean) {
            //     // console.log(`[close] Connection closed normally, code: ${event.code}, reason: ${event.reason}`);
            //     console.log(`[close] Connection closed normally. Code: ${event.code}` + ' - ' + timeString);
            //   } else {
            //     // например, сервер убил процесс или сеть недоступна
            //     // обычно в этом случае event.code 1006
            //
            //     // setTimeout(() => {
            //     //   if (!this.socketIsConnected) {
            //     //     this.socketConnectionIsTerminated = true
            //     //   }
            //     // }, 500)
            //     console.log('[close] Connection terminated' + ' - ' + timeString);
            //     if (!this.socketConnectionError) {
            //       this.createWebSocket(true)
            //     }
            //   }
            // })

            this.$store.state.webSocket.addEventListener('close', (event) => {
                const timeString = new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds()

                this.webSocket = null
                this.$store.commit('createWebSocket', null)
                // console.log('websocket To break off: ' + event.code + ' ' + event.reason + ' ' + event.wasClean)

                // if (settings.isDevMode) {
                //   console.log(event)
                // }

                if (!event.wasClean) {
                    console.log('[close] Connection terminated' + ' - ' + timeString);
                    // Uncomment to show ServerSocketError.vue instead of App.vue
                    setTimeout(() => {
                        if (!this.socketIsConnected) {
                            this.socketConnectionIsTerminated = true
                        }
                    }, 1500)

                    if (!this.socketConnectionError) {
                        this.createWebSocket(true)
                    }
                } else {
                    console.log(`[close] Connection closed normally. Code: ${event.code}` + ' - ' + timeString)
                }
            })

            // this.webSocket.addEventListener('error', () => {
            this.$store.state.webSocket.addEventListener('error', () => {
                this.webSocket = null
                this.$store.commit('createWebSocket', null)

                this.socketConnectionError = true
                console.log('[error] WebSocket connection error');
                this.$toast.add({severity:'error', summary: this.$t('Error'), detail: 'Server connection error', life: settings.toastLife})
            })

            // this.setSocketPingInterval()
        },
        closeWebSocket() {
            if (this.$store.state.webSocket?.readyState === 1) {
                // this.webSocket.close()
                this.$store.state.webSocket.close()
                this.webSocket = null
                this.$store.commit('createWebSocket', null)
            }
            // this.clearSocketPingInterval()
        },
        webSocketOnMessage(e) {
            if (!e?.data) return false
            const data = JSON.parse(e.data)
            // if (!data || data.host !== window.location.host) return false

            // console.log(data)

            switch(data.type) {
                // case 'open_order': {
                //   console.log('open_order')
                //   // this.$store.commit('changeOpenedOrderId', data.order_id)
                //   // this.$toast.add({severity: 'warn', detail: `Order #${data.order_number} opened by ${data.opened_by}`, life: 3000});
                //   // return false
                //   break
                // }
                // case 'close_order': {
                //   console.log('close_order')
                // //   this.$store.commit('changeOpenedOrderId', data.order_id)
                // //   this.$toast.add({severity: 'warn', detail: `Order #${data.order_id} closed`, life: 3000});
                // //   return false
                //   break
                // }
                case 'create_order': {
                    console.log('create_order')
                    // console.log('create_order')
                    // this.$toast.add({severity: 'warn', detail: `Order created`, life: 3000});
                    this.$store.commit('updateOrdersPage', true)
                    break
                }
                case 'update_order': {
                    // console.log('update_order')
                    // this.$toast.add({severity: 'warn', detail: `Order updated`, life: 3000});
                    // this.$store.commit('updateOrdersPage', true)
                    // console.log(data.order_id)
                    this.$store.commit('changeUpdatedOrderId', data.order_id)
                    break
                }
                case 'create_offer': {
                    console.log('create_offer')
                    // console.log('create_offer')
                    // this.$toast.add({severity: 'warn', detail: `Offer created`, life: 3000});
                    this.$store.commit('updateOffersPage', true)
                    break
                }
                case 'update_offer': {
                    // console.log('update_offer')
                    // this.$toast.add({severity: 'warn', detail: `Offer created`, life: 3000});
                    // console.log(data.offer_id)
                    this.$store.commit('changeUpdatedOfferId', data.offer_id)
                    break
                }
                case 'start_logout_countdown': {
                    console.log('start_logout_countdown websocket')
                    if (data.user_id === this.$store.state.user.id) {
                        this.startLogoutСountdown()
                    }
                    break
                }
                // case 'cancel_logout_countdown': {
                //     console.log('cancelLogoutCountdown websocket')
                //     if (data.user_id === this.$store.state.user.id) {
                //         // this.$refs.appTopBar.logout()
                //         this.cancelLogoutCountdown()
                //     }
                //     break
                // }
                case 'logout': {
                    // console.log('logout')
                    if (data.user_id === this.$store.state.user.id) {
                        if (this.$refs.appTopBar) {
                            this.$refs.appTopBar.logoutOnClick()
                        } else {
                            this.logout()
                        }
                    }
                    break
                }
            }
        },
        sendPriceRequestCreatedSocket() {
            let wsUri = settings.socketHost
            this.webSocket = new WebSocket(wsUri)
            // Don't remove Timeout
            setTimeout(() => {
                if (this.webSocket.readyState === 1) {
                    this.webSocket.send(JSON.stringify({'action' : 'createOffer'}))
                    this.webSocket.close()
                }
            }, 150)
        },
        sendAppointmentBookedSocket(offerId) {
            let wsUri = settings.socketHost
            this.webSocket = new WebSocket(wsUri)
            // Don't remove Timeout
            setTimeout(() => {
                if (this.webSocket.readyState === 1) {
                    this.webSocket.send(JSON.stringify({'action' : 'createOrder'}))
                    this.webSocket.send(JSON.stringify({'action' : 'updateOffer', 'offer_id' : offerId}))
                    this.webSocket.close()
                }
            }, 150)
        },
        sendUpdateOrderWebSocket(id) {
            if (this.$store.state.webSocket?.readyState === 1) {
                this.$store.state.webSocket.send(JSON.stringify({'action' : 'updateOrder', 'order_id' : id}))
            }
        },
        sendArchiveOrderWebSocket(id) {
            if (this.$store.state.webSocket?.readyState === 1) {
                this.$store.state.webSocket.send(JSON.stringify({'action' : 'archiveOrder', 'order_id' : id}))
            }
        },
        sendUpdateOfferWebSocket(id) {
            if (this.$store.state.webSocket?.readyState === 1) {
                this.$store.state.webSocket.send(JSON.stringify({'action' : 'updateOffer', 'offer_id' : id}))
            }
        },
        sendLogoutWebSocket(id) {
            if (this.$store.state.webSocket?.readyState === 1) {
                this.$store.state.webSocket.send(JSON.stringify({'action' : 'logout', 'user_id' : id}))
            }
        },
        sendStartLogoutCountdownWebSocket(id) {
            if (this.$store.state.webSocket?.readyState === 1) {
                this.$store.state.webSocket.send(JSON.stringify({'action' : 'startLogoutCountdown', 'user_id' : id}))
            }
        },
        sendCancelLogoutCountdownWebSocket(id) {
            if (this.$store.state.webSocket?.readyState === 1) {
                this.$store.state.webSocket.send(JSON.stringify({'action' : 'cancelLogoutCountdown', 'user_id' : id}))
            }
        },
    }
}

export default webSocketMixins
