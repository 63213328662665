import { createApp, h } from 'vue';
import { createStore } from 'vuex'
import { reactive } from 'vue';
import App from './App.vue';
import router from './router';
import AutoComplete from 'primevue/autocomplete';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Button from 'primevue/button';
import ScrollTop from "primevue/scrolltop";
// import Breadcrumb from 'primevue/breadcrumb';
import Card from 'primevue/card';
import Chart from 'primevue/chart';
import Checkbox from 'primevue/checkbox';
import ColorPicker from 'primevue/colorpicker';

import ContextMenu from 'primevue/contextmenu';
// import DataTable from 'primevue/datatable';
// import Column from 'primevue/column';
// import ColumnGroup from 'primevue/columngroup';
// import Row from 'primevue/row';
// import DataTableRemastered from 'primevue/datatable';
// import Dialog from 'primevue/dialog';
// import ConfirmPopup from "primevue/confirmpopup";
// import ConfirmationService from "primevue/confirmationservice";
import Dropdown from 'primevue/dropdown';
import FileUpload from 'primevue/fileupload';
import Calendar from 'primevue/calendar';
import InlineMessage from 'primevue/inlinemessage';
import InputSwitch from 'primevue/inputswitch';
import InputText from 'primevue/inputtext';
import InputMask from 'primevue/inputmask';
import InputNumber from 'primevue/inputnumber';
import Listbox from 'primevue/listbox';
import Menu from 'primevue/menu';
import MultiSelect from 'primevue/multiselect';
import OverlayPanel from 'primevue/overlaypanel';
// import Paginator from 'primevue/paginator';
import Panel from 'primevue/panel';
import Password from 'primevue/password';
import RadioButton from 'primevue/radiobutton';
import PrimeVue from 'primevue/config';
import Ripple from 'primevue/ripple';
import SelectButton from 'primevue/selectbutton';
import SplitButton from 'primevue/splitbutton';
import Slider from 'primevue/slider';
import Sidebar from 'primevue/sidebar';
import Steps from 'primevue/steps';
import TabMenu from 'primevue/tabmenu';
import Textarea from 'primevue/textarea';
// import Toast from 'primevue/toast';
import Toast from '@/components/toast';
import ToastService from 'primevue/toastservice';
import Toolbar from 'primevue/toolbar';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Tooltip from 'primevue/tooltip';
// import StyleClass from 'primevue/styleclass';
import Tree from 'primevue/tree';
import TreeTable from 'primevue/treetable';
import ProgressBar from 'primevue/progressbar';
import ProgressSpinner from 'primevue/progressspinner';
import CodeHighlight from './AppCodeHighlight';
import BadgeDirective from "primevue/badgedirective";
import Badge from 'primevue/badge';

// Own components
import Spinner from '@/components/Spinner'
import ColumnSorting from '@/components/DataTable/ColumnSorting'
import Modal from '@/components/Modal'
import DesktopDataTable from '@/components/DataTable/DesktopDataTable'
import MobileDataTable from '@/components/DataTable/MobileDataTable'
import TotalRecordsData from '@/components/DataTable/TotalRecordsData'
import AddNewButton from '@/components/DataTable/AddNewButton'
import MultipleDeleteButton from '@/components/DataTable/MultipleDeleteButton'
import ViewButton from '@/components/DataTable/ViewButton'
import EditButton from '@/components/DataTable/EditButton'
import DeleteButton from '@/components/DataTable/DeleteButton'
import ConfirmDeleteModal from '@/components/ConfirmDeleteModal'
import ConfirmModal from '@/components/ConfirmModal'
import ConfirmMultipleDeleteModal from '@/components/ConfirmMultipleDeleteModal'
import OwnPaginator from '@/components/DataTable/OwnPaginator'
// import DropdownWithSearch from "@/components/DropdownWithSearch";
import CustomDropdown from "@/components/CustomDropdown";
import SearchInput from "@/components/SearchInput";
import LimitedCharsTextarea from "@/components/LimitedCharsTextarea";

// import CKEditor from '@ckeditor/ckeditor5-vue';
import settings from "@/settings";

import 'primevue/resources/primevue.min.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css'
// import '@fullcalendar/core/main.min.css';
// import '@fullcalendar/daygrid/main.min.css';
// import '@fullcalendar/timegrid/main.min.css';
// import 'prismjs/themes/prism-coy.css';

// router.beforeEach(function(to, from, next) {
// 	window.scrollTo(0, 0);
//
// 	next();
// });


import Access from './pages/Access';
import Error from './pages/Error';
import Login from './pages/Login';
import ForgotPassword from './pages/ForgotPassword';
import NotFound from './pages/NotFound';
import CustomerOffer from "@/pages/price_request/CustomerOffer";
// import PriceRequestForm from "@/pages/price_request/PriceRequestForm";
import PriceRequest from "@/pages/price_request/PriceRequest";
import EmptyPage from "@/pages/EmptyPage";
// import OldVgOfferRedirect from "@/pages/price_request/components/OldVgOfferRedirect";

import authService from '@/services/auth.service'

let i = 0;
const app = createApp({
	computed: {
		ViewComponent() {
			const url = this.$route.path.split('/')[1]
			const routePath = '/' + url

			i++
			if (i === 1) {
				return EmptyPage
			}

			switch (routePath) {
				case '/o':
					return CustomerOffer;
				case '/ru':
					// return OldVgOfferRedirect;
					return CustomerOffer;
				case '/en':
					// return OldVgOfferRedirect;
					return CustomerOffer;
				case '/et':
					// return OldVgOfferRedirect;
					return CustomerOffer;
				// case '/price-request-form':
				// 	return PriceRequestForm;
				case '/price-request':
					return PriceRequest;
				case '/login':
					return Login;
				case '/forgot-password':
					return ForgotPassword;
				case '/error':
					return Error;
				case '/access':
					return Access;
				case '/notfound':
					return NotFound;
				default:
					if (authService.isLoggedIn()) {
						return App;
					} else {
						return Login
					}
			}
		}
	},
	render () { return h(this.ViewComponent) }
});

//i18n
import en from './translations/en'
import et from './translations/et'
import ru from './translations/ru'

import { createI18n } from 'vue-i18n/index'

const messages = {
	en,
	et,
	ru
}

const i18n = createI18n({
	locale: settings.defaultLanguage,
	messages,
	fallbackLocale: 'en',
	// globalInjection: true,
})

app.use(i18n);

//Vuex store
import store from '@/store'
const vuexStore = createStore(store)
app.use(vuexStore)

app.config.globalProperties.$appState = reactive({ inputStyle: 'outlined' });
app.config.globalProperties.$primevue = reactive({ ripple: true });

// app.use(ConfirmationService);
app.use(PrimeVue, {
	ripple: true,
});

app.use(ToastService);
app.use(router);
// app.use(settings);

// app.use(settings);

// app.use(CKEditor);

app.directive('tooltip', Tooltip);
app.directive('ripple', Ripple);
app.directive('code', CodeHighlight);
app.directive('badge', BadgeDirective);
// app.directive('styleclass', StyleClass);

app.component('Accordion', Accordion);
app.component('AccordionTab', AccordionTab);
app.component('AutoComplete', AutoComplete);
// app.component('Breadcrumb', Breadcrumb);
app.component('Button', Button);
app.component('ScrollTop', ScrollTop);
app.component('SplitButton', SplitButton);
app.component('Card', Card);
app.component('Chart', Chart);
app.component('Checkbox', Checkbox);
app.component('ColorPicker', ColorPicker);
app.component('ContextMenu', ContextMenu);
// app.component('DataTable', DataTable);
// app.component('Column', Column);
// app.component('ColumnGroup', ColumnGroup);
// app.component('Row', Row);
// app.component('DataTableRemastered', DataTableRemastered);
// app.component('Dialog', Dialog);
// app.component("ConfirmPopup", ConfirmPopup);
app.component('Dropdown', Dropdown);
app.component('FileUpload', FileUpload);
app.component('Calendar', Calendar);
app.component('InlineMessage', InlineMessage);
app.component('InputSwitch', InputSwitch);
app.component('InputText', InputText);
app.component('InputMask', InputMask);
app.component('InputNumber', InputNumber);
app.component('Listbox', Listbox);
app.component('Menu', Menu);
app.component('MultiSelect', MultiSelect);
app.component('OverlayPanel', OverlayPanel);
// app.component('Paginator', Paginator);
app.component('Panel', Panel);
app.component('Password', Password);
app.component('RadioButton', RadioButton);
app.component('SelectButton', SelectButton);
app.component('Slider', Slider);
app.component('Sidebar', Sidebar);
app.component('Steps', Steps);
app.component('TabMenu', TabMenu);
app.component('TabView', TabView);
app.component('TabPanel', TabPanel);
app.component('Textarea', Textarea);
app.component('Toast', Toast);
app.component('Toolbar', Toolbar);
app.component('Tree', Tree);
app.component('TreeTable', TreeTable);
app.component('ProgressBar', ProgressBar);
app.component('ProgressSpinner', ProgressSpinner);
app.component('Badge', Badge);

//Own components
app.component('Spinner', Spinner);
app.component('ColumnSorting', ColumnSorting);
app.component('Modal', Modal);
app.component('DesktopDataTable', DesktopDataTable);
app.component('MobileDataTable', MobileDataTable);
app.component('TotalRecordsData', TotalRecordsData);
app.component('AddNewButton', AddNewButton);
app.component('MultipleDeleteButton', MultipleDeleteButton);
app.component('ViewButton', ViewButton);
app.component('EditButton', EditButton);
app.component('DeleteButton', DeleteButton);
app.component('ConfirmDeleteModal', ConfirmDeleteModal);
app.component('ConfirmModal', ConfirmModal);
app.component('ConfirmMultipleDeleteModal', ConfirmMultipleDeleteModal);
app.component('OwnPaginator', OwnPaginator);
// app.component('DropdownWithSearch', DropdownWithSearch);
app.component('CustomDropdown', CustomDropdown);
app.component('SearchInput', SearchInput);
app.component('LimitedCharsTextarea', LimitedCharsTextarea);

app.mount('#app');