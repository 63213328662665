<template>
  <Modal :style="computedWindowSize"
         :visible="visible"
         class="p-fluid"
         @close="close">
    <template v-slot:header>
      <div class="p-dialog-title">Customer status history</div>
    </template>
    <Spinner v-if="isLoading" :isSmall="true"></Spinner>
    <div v-else class="p-formgrid p-grid">
      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12 table-wrapper">
        <table class="p-datatable p-datatable-striped p-datatable-sm p-datatable-hoverable-rows positions-table">
          <thead class="p-datatable-thead">
          <tr>
            <th style="width: 15px"></th>
            <th>{{ $t('Status')}}</th>
            <th>{{ $t('Comment')}}</th>
            <th>{{ $t('Created')}}</th>
          </tr>
          </thead>
          <tbody class="p-datatable-tbody" v-if="items?.length">
            <tr v-for="(item, index) of items" :key="index">
              <td>
                <div class="customer-status-wrapper">
                  <div class="customer-status-circle" :class="CustomerStatuses.find(s => s.id === item.status_id)?.color"></div>
                </div>
              </td>
              <td>
                <div v-if="item.status_id === 0">Status deleted</div>
                <div v-else>{{ CustomerStatuses.find(s => s.id === item.status_id)?.label[$i18n.locale] }}</div>
              </td>
              <td>
                <span v-if="item.comment">{{ item.comment }}</span>
                <i v-else class="ti-minus"></i>
              </td>
              <td>
                <span v-if="item.created_at">{{ formatDate(item.created_at) }}, </span>
                <span v-if="item.creator">
                    <span v-if="item.creator.type === constants.userTypes.legal_entity && item.creator.company_name">{{ item.creator.company_name }}</span>
                    <span v-else>{{ item.creator.first_name }} {{ item.creator.last_name }}</span>
                  </span>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
          <tr>
            <td class="no-data-block" colspan="9">{{ $t('No data available') }}</td>
          </tr>
          <!--          <div class="no-data-block"></div>-->
          </tbody>
        </table>
      </div>
    </div>

<!--    <div class="p-formgrid p-grid" v-else>-->

<!--      <div class="p-col-12 p-sm-12 p-md-12 p-lg-12">-->
<!--        <DesktopDataTable-->
<!--            :dataTableIsLoading="dataTableIsLoading"-->
<!--            :tableName="$t('Requests')"-->
<!--            :itemsArrayIsEmpty="itemsArrayIsEmpty"-->
<!--            :sort="sort"-->
<!--            :showHeader="false"-->
<!--            :showSearchInput="false"-->
<!--            @sort-items="sortItems"-->
<!--            :headers="[-->
<!--                  {name: 'Status', width: '15px',sortable: false},-->
<!--                  {name: 'Status', title: 'Status', width: '',sortable: false},-->
<!--                  {name: 'comments', title: 'Comment', width: '', sortable: false},-->
<!--                  {name: 'created_at', title: $t('Created'), width: '', sortable: false},-->
<!--              ]">-->
<!--          <template v-slot:body>-->
<!--            <tr v-for="(item, index) of items" :key="index">-->
<!--              <td>-->
<!--&lt;!&ndash;                <div v-if="customerStatus" class="customer-status-circle" :class="CustomerStatuses.find(s => s.id === item.status_id)?.color"></div>&ndash;&gt;-->
<!--                <div class="customer-status-wrapper">-->
<!--                  <div class="customer-status-circle" :class="CustomerStatuses.find(s => s.id === item.status_id)?.color"></div>-->
<!--                </div>-->
<!--              </td>-->
<!--              <td>-->
<!--                <div v-if="item.status_id === 0">Status deleted</div>-->
<!--                <div v-else>{{ CustomerStatuses.find(s => s.id === item.status_id)?.label[$i18n.locale] }}</div>-->
<!--              </td>-->
<!--              <td>-->
<!--                <span v-if="item.comment">{{ item.comment }}</span>-->
<!--                <i v-else class="ti-minus"></i>-->
<!--              </td>-->
<!--              <td>-->
<!--                <span v-if="item.created_at">{{ formatDate(item.created_at) }}, </span>-->
<!--                <span v-if="item.creator">-->
<!--                  <span v-if="item.creator.type === constants.userTypes.legal_entity && item.creator.company_name">{{ item.creator.company_name }}</span>-->
<!--                  <span v-else>{{ item.creator.first_name }} {{ item.creator.last_name }}</span>-->
<!--                </span>-->
<!--              </td>-->
<!--            </tr>-->
<!--          </template>-->
<!--        </DesktopDataTable>-->


<!--        <OwnPaginator v-show="items?.length"-->
<!--                      :rows="pagination.perPage"-->
<!--                      :currentPage="pagination.currentPage - 1"-->
<!--                      :totalPages="pagination.pageCount"-->
<!--                      :totalRecords="pagination.totalCount"-->
<!--                      :rowsPerPageOptions="[5,10,25,50,100,200]"-->
<!--                      :pageLinkSize="11"-->
<!--                      @page="changePage"-->
<!--                      @change-per-page="changePerPage"-->
<!--                      :changePaginationRoute="false">-->
<!--        </OwnPaginator>-->

<!--        <TotalRecordsData :pagination="pagination" :itemsArrayIsEmpty="itemsArrayIsEmpty"/>-->
<!--      </div>-->
<!--    </div>-->

    <template #footer>
      <Button :label="$t('Close')" icon="pi pi-times" class="p-button-text" @click="close"/>
    </template>
  </Modal>
</template>

<script>
// import settings from '@/settings'
import httpMixins from "@/mixins/httpMixins";
import formatMixins from "@/mixins/formatMixins";
// import datatableMixins from "@/mixins/datatableMixins";
import httpClient from "@/services/http.services";
import CustomerStatuses from "@/translations/states/CustomerStatuses";
import constants from "@/constants"

export default {
  // mixins: [ httpMixins, formatMixins ],
  mixins: [ httpMixins, formatMixins ],
  name: 'CustomerStatusHistoryModal',
  emits: ['close', 'items-loaded'],
  props: {
    // isLoading: Boolean,
    visible: Boolean,
    loadCustomerHistory: Boolean,
    // customerStatusHistory: Array,
    customerId: [Number, null]
  },
  data() {
    return {
      constants,
      CustomerStatuses,
      dataTableIsLoading: false,
      isLoading: true,
      fieldsExpand: 'creator',
      apiLink: 'user/get-customer-status-history',
      sort: {
        column: 'id',
        direction: '-',
      },
      items: [],
      // pagination: {
      //   totalCount: null,
      //   pageCount: null,
      //   currentPage: 1,
      //   perPage: 25
      // },
    }
  },
  watch: {
    loadCustomerHistory(value) {
      if (value) {
        this.getItems()
      }
    },
    // visible() {
    //   if (!this.visible) {
    //     this.submitted = false
    //     this.servicePositionsData = []
    //   }
    // },
  },
  methods: {
    async getItems() {
      this.dataTableIsLoading = true
      try {
        const { status, data } = await httpClient({
          url: this.apiLink,
          params: {
            customerId: this.customerId,
            // page: this.pagination.currentPage,
            // 'per-page': this.pagination.perPage,
            sort: this.sort.direction + this.sort.column,
            expand: this.fieldsExpand
          }
        })

        if (status === 200 && data) {
          // this.pagination.totalCount = +headers['x-pagination-total-count']
          // this.pagination.pageCount = +headers['x-pagination-page-count']
          this.items = data

          const arrayIsEmpty = !data?.length

          if (arrayIsEmpty) {
            this.pagination.pageCount = 1
            // this.pagination.currentPage = 1
            // this.$emit('positions-loaded')
          } else {
            // this.$emit('disable-request-button', false)
          }
        }
      } catch(err) {
        this.showError(err)
      } finally {
        this.isLoading = false
        this.dataTableIsLoading = false
        this.$emit('items-loaded')
      }
    },
    // changePage(data) {
    //   if (this.pagination.currentPage === data.page + 1) return false
    //   this.pagination.currentPage = data.page + 1
    //   this.getItems()
    // },
    // changePerPage(perPage) {
    //   if (this.pagination.perPage === perPage) return false
    //   this.pagination.perPage = perPage
    //   this.getItems()
    // },
    sortItems(columnName) {
      if (this.sort.column !== columnName) {
        this.sort.direction = ''
      } else {
        this.sort.direction = this.sort.direction === '' ? '-' : '';
      }
      this.sort.column = columnName
      this.getItems()
    },
    close() {
      this.$emit('close')
    },
  },
  computed: {
    computedWindowSize() {
      if (this.$store.state.mobileLayout) {
        return {position: 'absolute', top: 0, width: '95%', marginTop: '10px'}
      } else if (!this.$store.state.mobileLayout && this.$store.state.tabletLayout) {
        return {position: 'absolute', top: 0, width: '85%', marginTop: '15px'}
      } else {
        return {position: 'absolute', top: 0, width: '70%', maxWidth: '900px'}
      }
    },
  },

}
</script>

<style scoped lang="scss">
.table-wrapper {
  width: 100%;
  overflow-y: auto;
  .positions-table {
    width: 100%;
    border-collapse: collapse;
  }
}

.no-data-block {
  //width: 100%;
  //margin: 20px auto;
  padding: 30px 0;
  text-align: center;
  //background-color: red;
}
.customer-status-wrapper {
  margin-right: 3px;
  padding: 2px 2px 2px 0;
  border-radius: 2px;
  //background: yellow;
  //&:hover {
  //  opacity: 0.7;
  //}
}
//
//.customer-status-circle {
//  //display: flex;
//  //justify-content: center;
//  //align-items: center;
//  //padding: 2px;
//  height: 12px;
//  width: 12px;
//  border-radius: 50px;
//  background-color: #ccc;
//  //font-size: 0.9em;
//}
//
//.red {
//  background-color: #ff6464;
//}
//.green {
//  background-color: #60c666;
//}
//
//.yellow {
//  background-color: #fff700;
//}

</style>